<template>
    <div>
        <el-table :data="list" v-loading="loading" style="width: 100%" key="audio_list" header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
                <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column prop="id" label="序号" width="80"></el-table-column>
            <el-table-column prop="name" label="声音名称" width="100"></el-table-column>
            <el-table-column label="声音头像" width="140" align="center">
                <template slot-scope="scope">
                    <div class="scope_img">
                        <el-image style="width:40px; height: 40px;border-radius: 50%;" :src="scope.row.avatar" fit="scale-down" :preview-src-list="avatarList"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="label" label="声音标签" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="category" label="归属类型" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column label="是否免费">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_free==1">是</span>
                    <span v-if="scope.row.is_free==2">否</span>
                </template>
            </el-table-column>
            <el-table-column label="是否会员">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_vip==1">是</span>
                    <span v-if="scope.row.is_vip==2">否</span>
                </template>
            </el-table-column>
            <el-table-column prop="hits" label="点击热度"></el-table-column>
            <el-table-column prop="price" label="钻石数"></el-table-column>
            <el-table-column label="上架状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" width="170"></el-table-column>
            <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="editAudio(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" @click="delAudio(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page_box" v-if="total" style="padding-bottom: 20px;">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="market" @close='closeAudio' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">确定要删除该声音吗？删除后将无法恢复</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { PointOut, EmptyData, PagingPage },
    props: {},
    data() {
        return {
            formData: {
                name: '',
                theme_type: '',
                is_free: '',
                is_vip: '',
            },
            // 
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 
            delId: null,
            avatarList: []
        };
    },

    created() { },

    mounted() {
        this.getList()
    },

    methods: {
        // 列表数据
        getList() {
            var that = this
            that.loading = true
            var params = {
                ...that.formData,
                page: that.page,
                limit: that.limit,
            }
            that.$marketApi.marketAudio(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    console.log(res)
                    that.list = res.result.data || []
                    that.total = res.result.total || 0
                    that.list.forEach(ele => {
                        that.avatarList.push(ele.avatar)
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 编辑
        editAudio(val) {
            this.$router.push({
                path: '/market/audio/add_edit',
                query: {
                    id: val
                }
            })
        },
        // 搜索
        search(info) {
            this.formData = info
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 删除
        delAudio(val) {
            this.delId = val
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeAudio() {
            var that = this
            if (that.delId != null) {
                that.$marketApi.delMarketAudio(that.delId).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.delId = null
                        if (that.list.length == 1) {
                            that.search(that.formData)
                        } else {
                            that.getList()
                        }
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var that = this
            var params = {
                id: info.id,
                status: val
            }
            that.$marketApi.marketAudioStatus(params).then(res => {
                if (res.code == 1000) {
                    that.list[index].status = val
                    that.$succMsg(res.message)
                } else {
                    that.list[index].status = val == 1 ? 2 : 1
                    that.$errMsg(res.message)
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.scope_img {
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.videoimg {
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    background: #e9dcdc;
    .cover {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    .play {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.videoimg.heng {
    width: 48px;
    height: 27px;
}
.videoimg.shu {
    width: 27px;
    height: 48px;
}
</style>