<template>
    <div>
        <el-table :data="list" v-loading="loading" style="width: 100%" key="classify" header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
                <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column prop="id" label="序号"></el-table-column>
            <el-table-column prop="name" label="分类名称"></el-table-column>
            <el-table-column prop="sort" label="栏目排序"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="添加时间"></el-table-column>
            <el-table-column prop="create_time" label="更新时间"></el-table-column>
            <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="editClassify(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="delClassify(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page_box" v-if="total" style="padding-bottom: 20px;">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="market" @close='closeClassify' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">确定要删除该分类吗？删除后将无法恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" @affirm="getList"></add-edit>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddEdit from './classify_add_edit'// 添加/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, AddEdit },
    props: {

    },
    data() {
        return {
            // 
            formData: {
                name: "",
            },
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            delId: null,
        };
    },


    created() {
    },

    mounted() {

    },

    methods: {
        // 列表数据
        getList() {
            var that = this
            that.loading = true
            var params = {
                ...that.formData,
                page: that.page,
                limit: that.limit,
            }
            that.$marketApi.audioCategory(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result.data || []
                    that.total = res.result.total || 0
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 搜索
        search(info) {
            this.formData = info
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 添加
        addClassify() {
            this.$refs.addedit.openDialog()
        },
        // 编辑
        editClassify(info) {
            var obj = JSON.parse(JSON.stringify(info))
            this.$refs.addedit.openDialog(obj)
        },
        // 删除
        delClassify(info) {
            this.delId = info.id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeClassify() {
            if (this.delId != null) {
                this.$marketApi.delAudioCategory(this.delId).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.delId = null
                        if (this.list.length == 1) {
                            this.searchBtn()
                        } else {
                            this.getList()
                        }
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var params = {
                id: info.id,
                status: val
            }
            this.$marketApi.changeAudioCategory(params).then(res => {
                if (res.code == 1000) {
                    this.list[index].status = val
                    this.$succMsg(res.message)
                } else {
                    this.list[index].status = val == 1 ? 2 : 1
                    this.$errMsg(res.message)
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped></style>