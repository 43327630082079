<!-- @Author: Yu_Bo 2023-09-07 10:54:40-->
<template>
    <div class="model_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="nav_txt" :class="navIndex == 1 ? 'nav_active' : ''" @click="navBtn(1)">声音管理</div>
            <div class="nav_txt" :class="navIndex == 2 ? 'nav_active' : ''" @click="navBtn(2)">声音分类</div>
        </div>
        <!--  -->
        <div class="model_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addAudioClassify" size="small" icon="el-icon-plus">{{ navIndex == 1 ? '添加声音' : '添加分类' }}</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==1" :clearable="true" placeholder="搜索声音名称"></el-input>
                    <el-select v-model="category_id" size="small" @change="searchBtn" :clearable="true" placeholder="请选择归属类型" v-if="navIndex==1">
                        <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="is_free" size="small" @change="searchBtn" :clearable="true" placeholder="是否免费" v-if="navIndex==1">
                        <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="is_vip" size="small" @change="searchBtn" :clearable="true" placeholder="是否会员" v-if="navIndex==1">
                        <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==2" :clearable="true" placeholder="搜索分类名称"></el-input>
                </div>
            </div>
            <!-- 名片列表 -->
            <div class="main_table" v-show="navIndex == 1">
                <card-list ref="model"></card-list>
            </div>
            <div class="main_table" v-show="navIndex == 2">
                <classify-list ref="classify"></classify-list>
            </div>
        </div>

    </div>
</template>

<script>
import CardList from '../components/audio/audio_list'//模特列表
import ClassifyList from '../components/audio/classify_list'//分类列表
export default {
    components: { CardList, ClassifyList },
    props: {},
    data() {
        return {
            navIndex: 1,
            // 名称
            name: '',
            // 类型
            category_id: '',
            categoryList: [],
            // 是否免费
            is_free: '',
            freeList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            // 是否会员
            is_vip: '',
            vipList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
        };
    },

    created() { },

    mounted() {
        this.getCat()
    },

    methods: {
        // 参数
        getCat() {
            var that = this
            that.$marketApi.marketAudioCategory().then(res => {
                if (res.code == 1000) {
                    // 归属类型
                    that.categoryList = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 切换
        navBtn(val) {
            this.navIndex = val
            this.name = ''
            this.category_id = ''
            this.is_free = ''
            this.is_vip = ''
            this.searchBtn()
            this.getCat()
        },
        // 添加
        addAudioClassify() {
            if (this.navIndex == 1) {
                // 声音
                this.$router.push('/market/audio/add_edit')
            } else if (this.navIndex == 2) {
                // 分类
                this.$refs.classify.addClassify()
            }
        },
        // 搜索
        searchBtn() {
            if (this.navIndex == 1) {
                // 名片
                var obj = {
                    name: this.name,
                    category_id: this.category_id,
                    is_free: this.is_free,
                    is_vip: this.is_vip,
                }
                this.$refs.model.search(obj)
            } else if (this.navIndex == 2) {
                // 分类
                var obj = {
                    name: this.name,
                }
                this.$refs.classify.search(obj)
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.model_vue {
    width: 100%;

    .nav_txt {
        cursor: pointer;
        margin-right: 40px;
    }

    .nav_active {
        color: #2e4bf2;
    }

    .model_main {
        width: 100%;
        padding: 0 20px;

        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cond_left {
                display: flex;
                align-items: center;

                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }

                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }

                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>